<template>
    <div class="order">
        <div class="container">
            <h1 class="font-weight-normal">Please wait...</h1>
            <p><img src="@/assets/images/loading.gif" alt=""></p>
            <p>You are redirecting to Secure Payment Form.</p>
            <p class="img2"><img src="@/assets/images/cbcards.jpg" alt="" class="img-fluid"></p>
        </div>
    </div>
</template>

<script>
export default {
    mounted: function() {
        this.orderNow();
    },
    methods:{
        orderNow(){
            window.location = process.env.VUE_APP_LINK_ORDER
        }
    }
}
</script>

<style scoped>
.order {
    padding: 80px 0;
    text-align: center;
}
.order img {
    margin: 20px 0;
}
.order h1 {
    font-size: 2rem;
    margin: 20px 0;
}
.order p {
    font-size: 1rem;
    margin: 20px 0;
}
.order .img1 {
    max-width: 600px;
    width: auto;
    margin: 0 auto;
}
.order .img2 {
    border-top: 1px solid #EEE;
    padding-top: 10px;
    max-width: 700px;
    width: auto;
    margin: 0 auto;
}
</style>